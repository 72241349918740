import { DateTime } from "luxon";

export function formatMillisToDate(timestamp: number | string): string | undefined {
  return DateTime?.fromMillis(Number(timestamp)).toFormat("MMMM dd, yyyy");
}

export function isToday(timestamp: number) {
  const datetimeFromTimestamp = DateTime.fromMillis(timestamp);
  return datetimeFromTimestamp.hasSame(DateTime.now(), "day");
}

export function isTomorrow(timestamp: number) {
  const datetimeFromTimestamp = DateTime.fromMillis(timestamp);
  return datetimeFromTimestamp.hasSame(DateTime.now().plus({ days: 1 }), "day");
}

export function formatMillisToTime(timestamp: number | string): string | undefined {
  return DateTime?.fromMillis(Number(timestamp)).toFormat("HH:mm");
}
export function formatMillisToDateTime(timestamp: number | string): string | undefined {
  const dateTime = DateTime.fromMillis(Number(timestamp));
  const currentYear = DateTime.now().year;

  if (dateTime.year === currentYear) {
    return dateTime.toFormat("dd MMMM 'at' HH:mm");
  } else {
    return dateTime.toFormat("dd MMMM, yyyy 'at' HH:mm");
  }
}
export function convertToDate(date: number | string): string | undefined {
  const format = "dd MMM yyyy";
  if (typeof date === "number") {
    return DateTime?.fromMillis(date).toFormat(format);
  }

  if (!Number.isNaN(Number(date))) {
    return DateTime?.fromMillis(Number(date)).toFormat(format);
  } else {
    return DateTime?.fromISO(date).toFormat(format);
  }
}
