import { PropsWithChildren, ReactNode } from "react";
import { Button } from "./button";
import { Link } from "./Link";
import { textToSlug } from "../utils/helpers/utils";
import { Badge } from "./badges/badge";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

export type Breadcrumbs = {
  text: string;
  href?: string;
};
export type PageHeaderProps = PropsWithChildren & {
  backgroundImage?: string;
  breadcrumbs?: Breadcrumbs[];
  className?: string;
  icon?: ReactNode;
  pageTitle?: string;
  pageSubTitle?: string | ReactNode;
  description?: string;
  badge?: string;
  learnMoreLink?: string;
  buttonEventTrackName?: string;
  button?: ReactNode;
  buttonLabel?: string;
  onClick?: () => void;
};

export const PageHeader = ({
  backgroundImage = "",
  breadcrumbs = [],
  className = "",
  icon,
  pageTitle = "",
  pageSubTitle = "",
  description = "",
  badge = "",
  learnMoreLink = "",
  buttonEventTrackName = "",
  buttonLabel = "",
  button,
  onClick = () => {},
  children,
}: PageHeaderProps) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "right",
  };

  return (
    <div
      data-testid="header-background-image"
      className={"position-relative w-100 px-0 pt-7 " + className}
      style={backgroundImage ? { ...backgroundStyle, minHeight: "164px" } : {}}
    >
      <Breadcrumb>
        {breadcrumbs?.length ? (
          breadcrumbs.map(({ text, href }, index) => {
            const active = breadcrumbs.length - 1 === index && !href;
            return (
              <BreadcrumbItem key={index} active={active}>
                {active ? text : <Link href={href}>{text}</Link>}
              </BreadcrumbItem>
            );
          })
        ) : (
          <li className="breadcrumb-item" style={{ userSelect: "none" }}>
            &nbsp;
          </li>
        )}
      </Breadcrumb>
      <div className="d-flex h-100 mt-5 align-items-start justify-content-between">
        <div className="col-7 d-flex align-items-start h-100">
          {icon && (
            <div data-testid="svg-icon" className="p-0 me-3">
              {icon}
            </div>
          )}
          <div className="d-flex flex-column">
            <h3 className="my-0 mb-4">{pageTitle}</h3>
            {pageSubTitle ? (
              <h4 className="mt-4 mb-0">
                {pageSubTitle}
                {learnMoreLink ? (
                  <span className="fs-base text" style={{ color: "var(--bs-link-color)" }}>
                    <Link hideExternalLinkIcon={true} className="link-border" href={learnMoreLink}>
                      Learn more
                    </Link>
                  </span>
                ) : null}
              </h4>
            ) : description ? (
              <p>{description}</p>
            ) : null}
            {badge ? (
              <div className="mb-4">
                <Badge label={badge} />
              </div>
            ) : null}
            {children}
          </div>
        </div>
        {button ? (
          button
        ) : buttonLabel ? (
          <div className="">
            <Button
              name={buttonEventTrackName}
              data-testid={textToSlug(buttonLabel)}
              onClick={onClick}
              level="primary"
              theme="standard"
              rounded="pill"
              size="lg"
            >
              {buttonLabel}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
